import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BasicsRepository } from '../data/repository/basics.repository';
import { AssistantComponent } from '../ai/assistant/assistant.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchDialogComponent } from '../share/components/search-dialog/search-dialog.component';
import { NotificationService } from '../share/services/notification.service';
import * as Sentry from '@sentry/angular';
import { SheetRepository } from '../data/repository/sheet.repository';
import { UploaderService, UploadFileProgress } from '../share/services/uploader.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass'],
})
export class PanelComponent {
  @ViewChild('assistant') assistant: AssistantComponent;

  initializing: boolean = true;
  showTut: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
      }
    });
    await this.baseRepo.fetchDefaults();
    let showAppTour = localStorage.getItem('showAppTour');
    this.showTut = showAppTour !== '0';
    this.initializing = false;
    this.initMessaging();
    this.initSentryUser();
  }

  initMessaging() {
    this.notificationService.requestPermission();
    this.notificationService.receiveMessage();
  }

  async initSentryUser() {
    let employee = await this.sheetRepo.getMyEmployee();
    Sentry.setUser({
      email: employee.Email,
      id: employee.UserId,
      ip_address: '{{auto}}',
      username: `${employee.FullName.firstName} ${employee.FullName.lastName}`,
    });
  }


  @HostListener('window:keydown.control.b', ['$event'])
  openAssistant(event: Event) {
    this.assistant.open();
    event.preventDefault();
  }

  @HostListener('window:keydown.control.e', ['$event'])
  searchDialog(event: Event) {
    this.modalService.open(SearchDialogComponent, { size: 'lg' });
    event.preventDefault();
  }
}
